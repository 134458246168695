<template>
  <div>
    <!-- Topbar -->
    <div class="topbar">
      <div class="left">
        <a-button
          @click.prevent="() => decrementStage()"
          type="link"
          icon="left"
        ></a-button>
      </div>
      <div class="right">
        <div class="name">Select Incident Type</div>
      </div>
    </div>
    <!-- / Topbar -->

    <a-alert :message="topMessage"></a-alert>

    <!-- Incident Type Selector -->
    <div class="incident-type-selector-wrapper">
      <incident-type-selector></incident-type-selector>
    </div>
    <!-- / Incident Type Selector -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import IncidentTypeSelector from "./SelectIncidentType/IncidentTypeSelector.vue";
export default {
  components: { IncidentTypeSelector },
  methods: {
    ...mapActions("drillStarter", {
      decrementStage: "decrementStage",
    }),
  },
  computed: {
    ...mapGetters("drillStarter", {
      selectedMembers: 'selectedMembers'
    }),

    topMessage() {
      return 'You are starting a drill with ' +  this.selectedMembers.length + ' chosen ' + (this.selectedMembers.length == 1 ? 'responder' : 'responders')
    }
  }
};
</script>

<style scoped lang="scss">
.topbar {
  background: #fff;
  border-radius: 10px;
  padding: 13px 10px;
  margin-bottom: 15px;
  display: flex;

  .left {
    flex-shrink: 1;
    padding-right: 10px;
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    .name {
      font-weight: 500;
    }
  }
}

.incident-type-selector-wrapper {
  margin-top: 10px;
}
</style>