<template>
  <div>
    <div class="loader" v-if="isLoadingMembership">
      <a-spin></a-spin>
    </div>

     <a-input-search
        placeholder="Search..."
        style="width: 230px; margin-bottom: 15px"
        v-model="searchQuery"
      />

    <!-- Members -->
    <div class="members-wrapper" v-if="membersToShow.length">
      <a-row :gutter="20">
        <a-col
          :span="colSpan"
          v-for="member in membersToShow"
          :key="member.id"
          @click.prevent="toggleMemberSelected(member)"
          :class="{ selected: isMemberSelected(member) }"
        >
          <div class="member-list-item">
            <div class="left">
              <presence-thumbnail
                :owner-id="member.ownerId"
                :presence-id="member.id"
              >
              </presence-thumbnail>
            </div>
            <div class="middle">
              {{ member.displayName }}
            </div>
            <div class="right">
              <a-icon
                theme="twoTone"
                two-tone-color="#52c41a"
                v-if="isMemberSelected(member)"
                type="check-circle"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- / Members -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
const _ = require("lodash");
export default {
  components: { PresenceThumbnail },

  data() {
    return {
      searchQuery: "",
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("drillStarter", {
      isLoadingMembership: "isLoadingMembership",
      membership: "membership",
      selectedMembers: "selectedMembers",
    }),

    myPresenceId() {
      let myPresenceIds = _.map(this.presences, "id");
      let presenceIdsInOrganisation = _.map(this.membership, "id");
      let intersection = _.intersection(
        myPresenceIds,
        presenceIdsInOrganisation
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },

    membersToShow() {
      let notMe = _.filter(this.membership, (member) => {
        return member.id !== this.myPresenceId;
      });

      if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(notMe, (member) => {
          return member.displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }

      return notMe;
    },

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },
  },

  methods: {
    ...mapActions("drillStarter", {
      toggleMemberSelected: "toggleMemberSelected",
    }),

    isMemberSelected(member) {
      return this.selectedMembers.includes(member.id);
    },
  },
};
</script>

<style scoped lang="scss">
.member-list-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px;
  // &:last-child {
  //   margin-bottom: 0;
  // }

  //  &:hover {
  //   color: #5155ea;
  //   border: 1px solid #5155ea;
  // }
  &:hover {
    // background: #f9f9f9;
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  &.selected {
    // background: #eee !important;
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .left {
    flex-shrink: 1;
  }

  .middle {
    flex-grow: 1;
    padding-left: 15px;
  }

  .right {
    flex-shrink: 1;
    .anticon {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}
</style>