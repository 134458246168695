<template>
  <div>
    <!-- Topbar -->
    <div class="topbar">
      <div class="left">
        <a-button
          @click.prevent="() => decrementStage()"
          type="link"
          icon="left"
        >Back</a-button>
      </div>
      <div class="center">
        <div class="name">Select Responders</div>
      </div>
      <div class="right">
        <a-button type="primary" :disabled="!canProgress" @click.prevent="attemptProgress">Next <a-icon type="right"></a-icon></a-button>
      </div>
    </div>
    <!-- / Topbar -->

    <!-- Organisation Selector -->
    <div class="organisation-selector-wrapper">
      <organisation-selector></organisation-selector>
    </div>
    <!-- / Organisation Selector -->

    <!-- Member Selector -->
    <div class="member-selector-wrapper">
      <member-selector></member-selector>
    </div>
    <!-- / Member Selector -->

    <!-- Next -->
    <!-- <div class="actions">
      <a-button
        type="primary"
        :disabled="!canProgress"
        @click.prevent="attemptProgress"
        size="large"
        >Next</a-button
      >
    </div> -->
    <!-- / Next -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MemberSelector from "./SelectOrgAndResponders/MemberSelector.vue";
import OrganisationSelector from "./SelectOrgAndResponders/OrganisationSelector.vue";
export default {
  components: { OrganisationSelector, MemberSelector },
  methods: {
    ...mapActions("drillStarter", {
      decrementStage: "decrementStage",
      incrementStage: "incrementStage"
    }),

    attemptProgress() {
      if (!this.canProgress) {
        return false;
      }
      this.incrementStage();
    },
  },
  computed: {
    ...mapGetters("drillStarter", {
      selectedMembers: "selectedMembers"
    }),

    canProgress() {
      return this.selectedMembers.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
.topbar {
  background: #fff;
  border-radius: 10px;
  padding: 13px 10px;
  margin-bottom: 15px;
  display: flex;

  .left {
    flex-shrink: 1;
    padding-right: 10px;
  }

  .center {
    flex-grow: 1;
    display: flex;
    align-items: center;
    text-align: center;
    .name {
      flex-grow: 1;
      font-weight: 500;
      text-align: center;
    }
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
  }
}

.organisation-selector-wrapper {
  margin-top: 5px;
}

.member-selector-wrapper {
  margin-top: 15px;
}

.actions {
  margin-top: 15px;
}
</style>