<template>
  <div>
    <!-- Is loading -->
    <div class="text-center" v-if="isLoadingIncidentTypes">
      <a-spin />
    </div>
    <!-- / Is loading -->

    <!-- Options wrapper -->
    <div class="options-wrapper" v-if="!isLoadingIncidentTypes">
      <!-- Options -->
      <incident-type-option
        v-for="option in sosIncidentTypes"
        :key="option.id"
        :incident-type="option"
        :sos="true"
      ></incident-type-option>
      <!-- / Options -->

      <div class="options-separator"></div>

      <!-- Options -->
      <incident-type-option
        v-for="option in otherIncidentTypesSorted"
        :key="option.id"
        :incident-type="option"
        :categories="categories"
      ></incident-type-option>
      <!-- / Options -->
    </div>
    <!-- / Options wrapper -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import IncidentTypeOption from "./IncidentTypeSelector/IncidentTypeOption.vue";
export default {
  components: { IncidentTypeOption },
  computed: {
    ...mapGetters("drillStarter", {
      isLoadingIncidentTypes: "isLoadingIncidentTypes",
      sosIncidentTypes: "sosIncidentTypes",
      otherIncidentTypes: "otherIncidentTypes",
    }),

    otherIncidentTypesSorted() {
      let filtered = _.filter(this.otherIncidentTypes, (f) => {
        return f.incidentTypeGroupId;
      });
      return _.sortBy(filtered, "rank");
    },

    categories() {
      return _.filter(this.otherIncidentTypes, (f) => {
        return !f.incidentTypeGroupId;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.options-wrapper {
  padding-bottom: 20px;
  .incident-type-option {
    margin-bottom: 15px;
  }
}

.options-separator {
  padding-top: 30px;
}
</style>