<template>
  <div class="start-a-drill">
    <h1 style="font-size: 30px">Start A Drill</h1>

    <!-- Stage 0: Welcome -->
    <div v-if="stage == 0">
      <welcome></welcome>
    </div>
    <!-- / Stage 0: Welcome -->

    <!-- Stage 1: Select Responders -->
    <div v-if="stage == 1">
      <select-org-and-responders></select-org-and-responders>
    </div>
    <!-- / Stage 1: Select Responders -->

    <!-- Stage 2: Select Incident Type -->
    <div v-if="stage == 2">
      <select-incident-type></select-incident-type>
    </div>
    <!-- / Stage 2 -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Welcome from "./StartADrill/Welcome.vue";
import SelectOrgAndResponders from "./StartADrill/SelectOrgAndResponders.vue";
import SelectIncidentType from "./StartADrill/SelectIncidentType.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";
export default {
  components: { Welcome, SelectOrgAndResponders, SelectIncidentType },
  mixins: [RequiresNonHiddenPresences],
  computed: {
    ...mapGetters("drillStarter", {
      stage: "stage",
      selectedOrganisation: "selectedOrganisation",
    }),
  },
  created() {
    this.reset();
  },
  methods: {
    ...mapActions("drillStarter", {
      reset: "reset",
    }),
  },
};
</script>

<style>
</style>