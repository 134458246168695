<template>
  <div class="welcome-wrapper">
    <a-icon style="font-size: 60px" type="message" theme="filled" />

    <div class="p-wrapper">
      <p>
        Running a drill is a great way to try out teamSOS with a few colleagues.
      </p>

      <p>
        Drills are like other incidents, except that you handpick the people who
        will respond, rather than alerting a set response team.
      </p>

      <p>
        On the next screen you'll pick your responders and then 'Call for Help'.
      </p>

      <p>After an hour, your drill will be deleted.</p>
    </div>

    <div class="actions-wrapper">
      <a-button @click.prevent="incrementStage" type="primary" size="large"
        >Next</a-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("drillStarter", {
      incrementStage: "incrementStage",
    }),
  },
};
</script>

<style scoped lang="scss">
.welcome-wrapper {
  text-align: center;
  background: #fff;
  border-radius: 5px;
  padding: 50px;
  margin-top: 25px;
}

.p-wrapper {
  margin-top: 40px;
  font-size: 16px;
}

.actions-wrapper {
  margin-top: 40px;
}
</style>